import React, { useState, useLayoutEffect } from "react"
import { graphql } from "gatsby"
import { analyticClickEvent } from "../../components/googleAnalytics"
import {
  ArticleDate,
} from "../../styledComponents/article"
import LayoutNoStripe from "../../components/layoutNoStripe"
import SEO from "../../components/seo"
import {
  Section,
  SectionHeading,
} from "../../styledComponents/section"
import { BackgroundColourBlock } from "../../styledComponents/overView"
import {
  StyledVideoTestimonialArticle,
  StyledVideoTestimonialHeader,
  StyledVideoTestimonialVideo,
  StyledVideoTestimonialLede
} from "../../styledComponents/videoTestimonial"
import EventList from "../../components/EventList"
import { StyledButton } from "../../styledComponents/button"
import ShareTools from "../../components/socialLinks"
import { StyledShareSocialTools } from "../../components/Products/StyledPrimaryProductCard"


const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const InterviewRichardGordon = ({ data, location }) => {
  

  const [isWatchFullVersionOpen, setIsWatchFullVersionOpen] = useState(false);
  const [isAutopPlay, setAutoPlay] = useState(false);

  const toggleTestimonials = (value) => {
    analyticClickEvent("engagement", "CTA Button","Watch Full Richard Gordon Video",'')
    setIsWatchFullVersionOpen(value)
  }

  const pageUrl = `https://${process.env.GATSBY_DOMAIN_NAME}/quantum-touch/an-interview-with-richard-gordon-founder-of-quantum-touch`

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      const urlParams = new URLSearchParams(window.location.search)
      const autoplay = urlParams.get('autoplay')
      setAutoPlay(autoplay)
      return
    }
  }, [])
  return (
    <LayoutNoStripe displayNavBorder location={location} hideNewsletterSignUp>
      <SEO 
      title="An interview with Richard Gordon the founder of Quantum-Touch"
      description="Karina Grant, Quantum-Touch Instructor & Ambassador interviews Richard Gordon. Richard is the Founder of Quantum-Touch, is now 74, has been practising for over 4 decades and feels fantastic."
      url="/quantum-touch/an-interview-with-richard-gordon-founder-of-quantum-touch"
      image="https://www.karinagrant.co.uk/images/karina-grant-interviews-richard-gordon-placeholder.jpg"
      />
      <StyledVideoTestimonialArticle itemScope itemType="http://schema.org/Article">
        <StyledVideoTestimonialHeader>
          <h1 itemProp="headline">An interview with Richard Gordon the founder of Quantum-Touch</h1>
          <ArticleDate offScreen dateTime=''>Last updated on November 17th 2022</ArticleDate>
        </StyledVideoTestimonialHeader>
        <StyledVideoTestimonialVideo>
        {isWatchFullVersionOpen ? <figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio loading" ><div class="wp-block-embed__wrapper"><iframe loading="lazy" title="Karina Grant, Quantum-Touch Instructor & Ambassador interviews Richard Gordon" width="640" height="360" src={isAutopPlay ? 'https://www.youtube.com/embed/2Ewz6P_HUTg?&autoplay=1' : 'https://www.youtube.com/embed/2Ewz6P_HUTg?&autoplay=0'} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe></div></figure>
        :
        <figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio"><div class="wp-block-embed__wrapper"><iframe loading="lazy" title="Karina Grant, Quantum-Touch Instructor & Ambassador interviews Richard Gordon" width="640" height="360" src={isAutopPlay ? 'https://www.youtube.com/embed/5up5HPCmzfA?&autoplay=1' : 'https://www.youtube.com/embed/5up5HPCmzfA?&autoplay=0'} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe></div></figure>
        }
        </StyledVideoTestimonialVideo>
        <StyledVideoTestimonialLede>Karina Grant, Quantum-Touch Instructor & Ambassador interviews Richard Gordon. Richard is the Founder of Quantum-Touch, is now 74, has been practising for over 4 decades and feels fantastic.</StyledVideoTestimonialLede><StyledVideoTestimonialLede>In this interview Richard talks about some of his earlier jaw dropping experiences with Quantum-Touch, his future plans and how he integrates this amazing energy healing modality into his life.</StyledVideoTestimonialLede>
        {!isWatchFullVersionOpen ? 
          <StyledButton style={{'marginTop':'0'}} className="read-more" onClick={() => toggleTestimonials(true)}>Watch the full video</StyledButton>
        :
          <StyledButton style={{'marginTop':'0'}} className="read-more" onClick={() => toggleTestimonials(false)}>Watch the short video</StyledButton>
        }

      <StyledShareSocialTools type="video">
        {}
      <ShareTools shareTitle="Share this on" pageLocation={pageUrl} postTitle='An interview with Richard Gordon the founder of Quantum-Touch' />
      </StyledShareSocialTools>
        
      </StyledVideoTestimonialArticle>

      
    </LayoutNoStripe>
  )
}

export default InterviewRichardGordon

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    wpPage(slug: { eq: "homepage" }) {
      title
      slug
      content
      testimonials {
        blockquote {
          citeLocation
          citationName
          quote
        }
        testimonialHeading
        testimonialLede
      }
    }

    aboutKarina: wpPage(slug: { eq: "about" }) {
      aboutKarina {
        aboutKarina
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
      

    allWpPost(sort: { fields: [date], order: DESC } limit: 5) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        isSticky
        categories {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }


    allWpProduct(limit: 3) {
      edges {
        node {
          title
          uri
          excerpt
          productsTag {
            nodes {
              name
            }
          }
          productData {
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            hidesale
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
}
`
